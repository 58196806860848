<template>
  <div class="list">
    <div class="header_button">
      <el-button type="primary" @click="add">新增</el-button>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%">
      <el-table-column type="index"></el-table-column>
      <el-table-column
        label="藏品名称"
        width="100">
        <template slot-scope="scope">
          <div @click="readDetail(scope.row.id)" class="title">{{ scope.row.title }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="藏品图片"
        width="100">
        <template slot-scope="scope">
          <img style="width:50px;" :src="$host+scope.row.imgUrl" alt="">
        </template>
      </el-table-column>
      <el-table-column
        prop="description"
        label="藏品简介">
      </el-table-column>
      <el-table-column
        prop="content"
        label="藏品内容">
      </el-table-column>
      <el-table-column
        prop="address"
        label="藏品解说音频"
        width="230px">
        <template slot-scope="scope">
          <audio style="width:220px;" :src="$host+scope.row.audioUrl" controls muted />
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="edit(scope.row)">编辑</el-button>
          <el-button
            size="mini"
            type="danger"
            @click="_delete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        layout="prev, pager, next"
        :page-size="params.size"
        @current-change="currentChange"
        :total="params.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      params: {
        num: 1,
        size: 3,
        total: 1
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.$fetch({
        url: '/getCollectionList',
        method: 'post',
        data: {
          pageNumber: this.params.num,
          pageSize: this.params.size
        }
      }).then((res) => {
        if (res.code == 200) {
          this.params.total = res.data.total
          this.tableData = res.data.data
          if (res.data.data.length == 0 && this.params.num > 1) {
            this.params.num -= 1
            this.getList()
          }
        } else {
          this.$message.error(res.msg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    currentChange(arg) {
      this.params.num = arg
      this.getList()
    },
    add() {
      this.$router.push('/calendar/addOrEdit')
    },
    readDetail(id) {
      this.$router.push({
        path: '/calendar/detail',
        query: {
          id
        }
      })
    },
    edit(row) {
      this.$router.push({
        path: '/calendar/addOrEdit',
        query: {
          id: row.id
        }
      })
    },
    _delete(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$fetch({
          url: '/deleteCollection',
          method: 'post',
          data: {
            id: row.id
          }
        }).then((res) => {
          if (res.code ==200) {
            this.$message.success('删除成功')
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        }).catch((err) => {
          console.log(err)
        })
      }).catch(() => {});
    }
  }
}
</script>

<style lang="less" scoped>
.list {
  padding: 30px;
  background-color: #fff;

  .header_button {
    text-align: right;
  }
  .pagination {
    text-align: right;
  }
  .title {
    text-decoration:underline;
    color: skyblue;
    cursor: pointer;
  }
}
</style>